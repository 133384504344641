<template>
  <AutomatedPluginForm
    :form="form"
    @update-value="(field, val) => $emit('update-value', field, val)"
    @clone-configuration="$emit('clone-configuration')"
    @remove-configuration="$emit('remove-configuration')"
  >
    <template #plugin-options>
      <template v-if="form.type === 'single'">
        <SingleAutomatedExportFormFields
          :config="form"
          :export-type-options="exportTypeOptions"
          :contact-export-choice-options="contactExportChoiceOptions"
          :segment-options="segmentOptions"
          :action-export-contact-choice-options="actionExportContactChoiceOptions"
          :action-export-code-choice-options="actionExportCodeChoiceOptions"
          :action-code-options="actionCodeOptions"
          :show-export-delta-files-checkbox="showExportDeltaFilesCheckbox"
          :show-create-empty-files-checkbox="showCreateEmptyFilesCheckbox"
          :show-file-name-input="showFileNameInput"
          :file-name-example="fileNameExample"
          @update-field="(key, value) => updateFieldValue(null, key, value)"
        />
      </template>
      <template v-if="form.type === 'grouped'">
        <div>
          <v-checkbox
            v-model="createControlFile"
            color="#2b84eb"
            label="Create Control File"
            class="custom-checkbox"
            @change="handleControlFileCheckbox"
          />
          <div class="v-text-field__details" style="padding: 0 12px;">
            <div class="v-messages theme--light">
              <div class="v-messages__message">
                If checked, a control file will be created when this configuration runs. The control file will be uploaded to the same directory and will include the file name and number of rows for all exports created by the configuration.
              </div>
            </div>
          </div>
        </div>
        <div v-if="!!createControlFile" class="mt-3">
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <div class="field__subheader filename-tooltip">
                <span class="field__subheader--required mr-1">*</span>
                Control File Name
                <v-icon
                  class="ml-1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </div>
            </template>
            <div class="d-flex flex-column">
              <span class="mb-1">Use the following placeholders to add dynamic values to the file name:</span>
              <span
                v-for="(tooltip, placeholder) in dynamicTimestampTooltips"
                :key="'filename-' + placeholder"
              >
                {{ placeholder }} - {{ tooltip.name }} ({{ tooltip.exampleValue }})
              </span>
            </div>
          </v-tooltip>
          <CustomTextInput
            :value="form.controlFileName"
            :required="createControlFile"
            :hint="getFileNameHint(form.controlFileName)"
            @input="val => $emit('update-value', 'controlFileName', val)"
          />
        </div>
        <div class="mt-5">
          <div>
            <span class="field__subheader--required mr-1">*</span>
            Exports:
          </div>
          <div class="grouped-exports-container mt-5">
            <div
              v-for="(config, i) in form.exports"
              :key="'grouped-export-' + i"
              class="grouped-export mb-3"
            >
              <v-expansion-panels v-model="config.isShowing">
                <v-expansion-panel>
                  <v-expansion-panel-header v-if="config.name">
                    {{ config.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-header v-else>
                    Grouped Export #{{ i + 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <SingleAutomatedExportFormFields
                      :config="config"
                      :export-type-options="exportTypeOptions"
                      :contact-export-choice-options="contactExportChoiceOptions"
                      :segment-options="segmentOptions"
                      :action-export-contact-choice-options="actionExportContactChoiceOptions"
                      :action-export-code-choice-options="actionExportCodeChoiceOptions"
                      :action-code-options="actionCodeOptions"
                      :show-export-delta-files-checkbox="showExportDeltaFilesCheckbox"
                      :show-create-empty-files-checkbox="showCreateEmptyFilesCheckbox"
                      :show-file-name-input="showFileNameInput"
                      :file-name-example="fileNameExample"
                      class="mt-4"
                      @update-field="(key, value) => updateFieldValue(i, key, value)"
                    >
                      <template #single-export-form-pre-extra-fields>
                        <CustomTextInput
                          :value="config.name"
                          required
                          header="Grouped Export Name"
                          hint="This name will be used to identify this configuration. You may see it in notification emails. We recommend setting a value that can easily identify this configuration."
                          @input="val => updateFieldValue(i, 'name', val)"
                        />
                      </template>
                      <template #single-export-form-extra-fields>
                        <v-row class="mt-4">
                          <v-col cols="12" class="d-flex justify-end">
                            <v-btn
                              class="custom-button custom-button--red px-13"
                              height="34px"
                              depressed
                              :block="$vuetify.breakpoint.xsOnly"
                              @click="removeExportFromGroup(i)"
                            >
                              Delete
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </SingleAutomatedExportFormFields>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>

          <v-btn
            class="custom-button custom-button--blue px-13 mt-5"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="addExportToGroup"
          >
            Add Export to Group
          </v-btn>
        </div>
      </template>
    </template>
  </AutomatedPluginForm>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import AutomatedPluginForm from "@/views/Plugins/components/AutomatedPluginForm";
import SingleAutomatedExportFormFields from "@/views/Plugins/components/SingleAutomatedExportFormFields";

export default {
  name: "AutomatedExportForm",
  components: {
    CustomTextInput,
    AutomatedPluginForm,
    SingleAutomatedExportFormFields,
  },
  mixins: [importExportMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
    exportTypeOptions: {
      type: Array,
      default: () => [],
    },
    contactExportChoiceOptions: {
      type: Array,
      default: () => [],
    },
    segmentOptions: {
      type: Array,
      default: () => [],
    },
    actionExportContactChoiceOptions: {
      type: Array,
      default: () => [],
    },
    actionExportCodeChoiceOptions: {
      type: Array,
      default: () => [],
    },
    actionCodeOptions: {
      type: Array,
      default: () => [],
    },
    showExportDeltaFilesCheckbox: {
      type: Boolean,
      default: true,
    },
    showCreateEmptyFilesCheckbox: {
      type: Boolean,
      default: true,
    },
    defaultExportConfig: {
      type: Object,
      default: null,
    },
    showFileNameInput: {
      type: Boolean,
      default: true,
    },
    fileNameExample: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      createControlFile: false,
    };
  },
  mounted() {
    if ((this.form?.type ?? 'single') === 'grouped') {
      this.createControlFile = this.form.createControlFile;
    }
  },
  methods: {
    handleControlFileCheckbox(val) {
      this.$emit('update-value', 'createControlFile', !!val);

      if (!val) {
        this.$emit('update-value', 'controlFileName', null);
      }
    },
    updateFieldValue(index, key, value) {
      if (index === null) {
        this.$emit('update-value', key, value);
        return;
      }

      const exports = [...(this.form?.exports ?? [])];
      if (key.includes('.')) {
        const [parentKey, childKey] = key.split('.');

        exports[index][parentKey][childKey] = value;
      } else {
        exports[index][key] = value;
      }

      this.$emit('update-value', 'exports', [...exports]);
    },
    addExportToGroup() {
      this.$emit('update-value', 'exports', [
        ...(this.form?.exports ?? []),
        this.generateExportConfiguration(
          this.defaultExportConfig ? {...this.defaultExportConfig} : null,
          0 // 0 means it will be open
        ),
      ]);
    },
    removeExportFromGroup(index) {
      const exports = [...this.form.exports];
      exports.splice(index, 1);

      this.$emit('update-value', 'exports', [...exports]);
    },
  },
};
</script>

<style scoped lang="scss">
.filename-tooltip {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: -4px;
}

::v-deep .custom-checkbox {
  &.v-input--selection-controls {
    margin-top: 0;
  }

  .v-label {
    font-size: 13px;
  }
}

.grouped-configuration .grouped-export {
  ::v-deep .v-expansion-panel-content__wrap {
    background-color: white;
  }
}
</style>
